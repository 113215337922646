<template>
  <nav class="MainNav">
    <div class="Container">
      <div v-if="width > 960" class="MainNav-Content">
        <router-link to="/" class="MainNav-Logo">
          <img src="../assets/images/logo.svg" alt="logo" class="MainNav-LogoLink">
        </router-link>
        <div class="MainNav-Menu">
          <router-link to="about-us" class="MainNav-Link">About us</router-link>
          <router-link to="services" class="MainNav-Link">Services</router-link>
          <router-link to="partners" class="MainNav-Link">Partners</router-link>
          <router-link to="contacts" class="MainNav-Link">Contacts</router-link>
        </div>
      </div>
      <router-link v-if="width < 960" to="/" class="MainNav-Logo">
        <img src="../assets/images/logo.svg" alt="logo" class="MainNav-LogoLink">
      </router-link>
      <button v-if="width < 960"
              class="NavBtn"
              @click.prevent="toggleNav">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <transition v-if="width < 960" name="slide-right">
        <div class="NavMobile"
             v-show="navIsOpen"
        >
          <div class="Close"
               @click.prevent="toggleNav"
          >
            <span></span>
            <span></span>
          </div>
          <router-link
              to="/" class="MainNav-Logo MainNav-Logo--mobile"
              @click.native="toggleNav"
          >
            <img src="../assets/images/logo.svg" alt="logo" class="MainNav-LogoLink">
          </router-link>
          <router-link
              @click.native="toggleNav"
              class="Nav-Item"
              v-for="(page, index) in pages"
              :key="index"
              :to="page.link">{{ page.name }}
          </router-link>
        </div>
      </transition>
    </div>
  </nav>
</template>

<script>
export default {
  name: "MainNav",

  data() {
    return {
      width: 0,
      navIsOpen: false,
      documentIsScrolled: false,
      pages: [
        // {link: "/", name: "home"},
        {link: "/about-us", name: "about"},
        {link: "/services", name: "services"},
        {link: "/partners", name: "partners"},
        {link: "/contacts", name: "contacts"},
      ],
    }
  },
  mounted() {
    if (window.scrollY > 0) this.documentIsScrolled = true;
    window.addEventListener('scroll', this.onScroll, {passive: true});
    this.width = window.innerWidth;
  },
  methods: {
    onScroll() {
      this.documentIsScrolled = window.scrollY > 0;
    },
    toggleNav() {
      if (this.navIsOpen) {
        this.navIsOpen = false;
      } else {
        this.navIsOpen = true;
      }
    },
  },
}
</script>

<style scoped lang="scss">
.MainNav {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 36px 0 28px;
  background-color: var(--color-text-main3);
  box-shadow: 0px -2px 10px rgba(29, 29, 37, 0.15);

  @media (min-width: $screen-l) {
    padding: 32px 0;
  }

  &-Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-Menu {
    display: flex;
    align-items: center;
  }

  &-Link {
    margin-right: 30px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.21;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      bottom: -2px;
      background-color: var(--color-text-main);
      opacity: 0;
      transition: .3s;
    }

    &:last-child {
      margin-right: 0;

      &:after {
        display: none;
      }
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }

  .router-link-exact-active {
    &:after {
      opacity: 1;
    }
  }

  &-Logo {
    width: 220px;

    @media (min-width: $screen-l) {
      width: 250px;
    }
  }

  &-Logo--mobile {
    margin-bottom: 60px;
  }

  .Phone {
    padding-left: 20px;
    color: var(--color-text-main2);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 18px;
      height: 18px;
      background-image: url(~@/assets/images/phone.svg);
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .Phone--mobile {
    font-size: 20px;
  }
}

.Nav {
  padding: 49px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 22px;
  text-transform: uppercase;
  color: var(--color-text-main2);


  &-Item {
    margin-bottom: 40px;
  }

  .router-link-exact-active {
    color: var(--color-text-main2);
  }
}

.NavBtn {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid var(--color-text-main);
  border-radius: 50%;

  position: absolute;
  top: 28px;
  right: 20px;

  span {
    display: block;
    width: 30px;
    height: 4px;
    background-color: var(--color-text-main);
  }

  span + span {
    margin-top: 4px;
  }
}

.NavMobile {
  position: fixed;
  padding-top: 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-text-main3);

  font-family: 'Lexend', sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  transform: translate(0, 0);

  .router-link-exact-active {
    text-decoration: underline;
  }
}

.slide-right-enter-active, .slide-right-leave-active {
  transition: all .5s ease;
}

.slide-right-enter, .slide-right-leave-to {
  transform: translate(100%, 0);
  opacity: 0;
}

.Close {
  span {
    width: 20px;
    height: 2px;
    position: absolute;
    top: 40px;
    right: 40px;
    background-color: var(--color-text-main);
  }

  span:nth-child(1) {
    transform: rotateZ(-45deg);
  }

  span:nth-child(2) {
    transform: rotateZ(45deg);
  }
}
</style>