<template>
  <div id="app">
    <MainNav/>
    <router-view/>
    <MainFooter/>
  </div>
</template>

<script>
import MainNav from "@/components/MainNav";
import MainFooter from "@/components/MainFooter";

export default {
  name: 'App',
  components: {
    MainFooter,
    MainNav
  },
  metaInfo() {
    return {
      title: "Loop limited",
      meta: [
        {
          name: "description",
          content: "",
        },
        {
          property: 'og:title',
          content: '',
        },
        {
          property: 'og:url',
          content: '',
        },
        {
          property: 'og:description',
          content: "",
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:img',
          content: '',
        },
        {
          property: 'og:site_name',
          content: 'Loop limited',
        },
        {
          property: 'og:locale',
          content: 'en_EN',
        }
      ],
    };
  },
}
</script>

<style lang="scss">

</style>
